import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyASCATeMDDpwbzuvTgUhrQvxoCwqgWo1AQ',
  authDomain: 'codex-nuevo-pacto.firebaseapp.com',
  projectId: 'codex-nuevo-pacto',
  storageBucket: 'codex-nuevo-pacto.appspot.com',
  messagingSenderId: '470000703476',
  appId: '1:470000703476:web:a2903e933b3c0f66868ba1',
  measurementId: 'G-PEQTR5PZ9Q',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
